import settings from "@/settings";
import { getToken } from "@/utils/auth";

const title = settings.title;

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}

/**
 * 动态插入css
 */

export const loadStyle = (url) => {
  const link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = url;
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(link);
};
/**
 * 设置浏览器头部标题
 */
export const setTitle = function (title) {
  title = title ? `${title}` : "NxAdmin";
  window.document.title = title + "-nxAdmin";
};

export const substr = function (str, len) {
  var newLength = 0;
  var newStr = "";
  var chineseRegex = /[^\x00-\xff]/g;
  var singleChar = "";
  var strLength = str.replace(chineseRegex, "**").length;
  for (var i = 0; i < strLength; i++) {
    singleChar = str.charAt(i).toString();
    if (singleChar.match(chineseRegex) != null) {
      newLength += 2;
    } else {
      newLength++;
    }
    if (newLength > len) {
      break;
    }
    newStr += singleChar;
  }

  if (strLength > len) {
    newStr += "...";
  }
  return newStr;
};

const strSimilarity2Number = function (s, t) {
  var n = s.length,
    m = t.length,
    d = [];
  var i, j, s_i, t_j, cost;
  if (n == 0) return m;
  if (m == 0) return n;
  for (i = 0; i <= n; i++) {
    d[i] = [];
    d[i][0] = i;
  }
  for (j = 0; j <= m; j++) {
    d[0][j] = j;
  }
  for (i = 1; i <= n; i++) {
    s_i = s.charAt(i - 1);
    for (j = 1; j <= m; j++) {
      t_j = t.charAt(j - 1);
      if (s_i == t_j) {
        cost = 0;
      } else {
        cost = 1;
      }
      d[i][j] = Minimum(
        d[i - 1][j] + 1,
        d[i][j - 1] + 1,
        d[i - 1][j - 1] + cost
      );
    }
  }
  return d[n][m];
};

const Minimum = function (a, b, c) {
  return a < b ? (a < c ? a : c) : b < c ? b : c;
};

//两个字符串的相似程度，并返回相似度百分比
export const strSimilarity2Percent = function (s, t) {
  if ((s == undefined && t == undefined) || (s == "" && t == ""))
    return Number(1).toFixed(4);
  var l = s.length > t.length ? s.length : t.length;
  var d = strSimilarity2Number(s, t);
  return (1 - d / l).toFixed(4);
};

export function strMBFormat(size) {
  if (size < 1) {
    return size * 1000 + "K";
  } else if (size > 1000) {
    return (size / 1000).toFixed(1) + "G";
  } else {
    return size + "M";
  }
}

export function downloadFile({ url, name }) {
  let isUrl = typeof url == "string";

  let downloadElement = document.createElement("a");

  let href = isUrl ? url : window.URL.createObjectURL(url); //创建下载的链接

  downloadElement.style.display = "none";
  downloadElement.href = url;
  downloadElement.download = name; //下载后文件名
  document.body.appendChild(downloadElement);
  downloadElement.click(); //点击下载
  document.body.removeChild(downloadElement); //下载完成移除元素
  if (!isUrl) window.URL.revokeObjectURL(href); //释放blob对象
}

export function previewOrDownload({ url, name, type, previewRoute }) {
  const mime = require("mime-types");
  if (!type) type = mime.lookup(url);
  if (!previewRoute) previewRoute = settings.browser.directOpen.route;
  let viewFlag =
    type &&
    settings.browser.directOpen.mime.findIndex((p) => {
      let index = p.indexOf("*");
      return index > -1 ? type.indexOf(p.substring(0, index)) == 0 : type == p;
    }) > -1;

  if (viewFlag && previewRoute) {
    //let route = previewRoute + `?url=${url}&name=${name}`;
    //window.open(route);
    window.open(url);
  } else {
    downloadFile({ url, name });
  }

  return false;
}

function trim_nulls(data) {
  var y;
  for (var x in data) {
    y = data[x];
    if (
      y === "null" ||
      y === null ||
      y === "" ||
      typeof y === "undefined" ||
      (y instanceof Object && Object.keys(y).length == 0)
    ) {
      delete data[x];
    }
    if (y instanceof Object) y = trim_nulls(y);
  }
  return data;
}

export function createElementFromHTML(htmlString) {
  var div = document.createElement("div");
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes.
  return div.firstChild;
}

export function openWindowWithToken(url, params) {
  window.open(
    url +
      (url.indexOf("?") > -1 ? "&" : "?") +
      new URLSearchParams(
        trim_nulls(Object.assign({}, params, { access_token: getToken() }))
      )
  );
}

export function openPostWindow(url, params) {
  url =
    url +
    (url.indexOf("?") > -1 ? "&" : "?") +
    new URLSearchParams(trim_nulls({ access_token: getToken() }));
  var form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("action", url);
  form.setAttribute("target", "_blank"); // 在新窗口中打开

  // 递归地添加数据到表单
  function appendData(parent, key, value) {
    if (Array.isArray(value)) {
      for (var i = 0; i < value.length; i++) {
        appendData(parent, key + "[]", value[i]);
      }
    } else if (typeof value === "object" && value !== null) {
      // 确保值不是 null
      for (var k in value) {
        if (Object.prototype.hasOwnProperty.call(value, k)) {
          // 使用Object.prototype.hasOwnProperty来检查属性
          appendData(parent, key + "[" + k + "]", value[k]);
        }
      }
    } else {
      var input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", value);
      parent.appendChild(input);
    }
  }

  // 添加数据到表单
  Object.keys(params).forEach((key) => {
    if (params[key] != null) {
      appendData(form, key, params[key]);
    }
  });

  console.log(form);
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export function delayExec(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
